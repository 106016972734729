<template>
    <!-- <div class="form-popup" @click="nextStep()"> -->
  <div class="form-popup">
    <!-- <div class="parent-form-container" @click.stop=""> -->
    <div class="parent-form-container">
      <b-overlay :show="showOverlay" class="form-container">
        <header>
          <div class="header-part">
            <span> Ajouter un nouveau logement</span>
            <i class="ik ik-x clos" @click="close()"></i>
          </div>
          <div class="progress-area">
            <div class="step-item">
              <span :class="{ active: step == firststep }">
                Informations de base
              </span>
              <div class="bullet" :class="{ active: step == firststep }">
                <span v-show="step == firststep">1</span>
                <div v-show="step > firststep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == secondstep }"
                >Caracteristiques
              </span>
              <div class="bullet" :class="{ active: step == secondstep }">
                <span v-show="step <= secondstep">2</span>
                <div v-show="step > secondstep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item" v-if="idBatiment == null">
              <span :class="{ active: step == thirdstep }"> Localisation </span>
              <div class="bullet" :class="{ active: step == thirdstep }">
                <span v-show="step <= thirdstep">3</span>
                <div v-show="step > thirdstep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == lastStep }"> Photos </span>
              <div class="bullet" :class="{ active: step == lastStep }">
                <span v-show="step <= lastStep">4</span>
                <div v-show="step > lastStep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="main-form">
          <div class="error-popup" v-show="incompleteData">
            <i class="ik ik-alert-triangle"></i>
            <span>
              {{ customErrorMsg }}
            </span>
          </div>
          <section v-show="step == firststep" class="basic-data">
            <div class="input-container">
              <div class="input-item">
                <span>Type logement*</span>
                <select @change="changeType" v-model="type">
                  <option :value="null" disabled>
                    Choisir le type de logement
                  </option>
                  <option
                    v-for="(typLog, cle) in typesLogement"
                    :key="cle"
                    :value="typLog"
                  >
                    {{ typLog.libelleType }}
                  </option>
                </select>
              </div>
              <div class="input-item">
                <span>Categorie associée*</span>
                <select v-model="sousType">
                  <option :value="null" disabled>
                    Choisir une sous catégorie
                  </option>
                  <option v-if="type == null" disabled>
                    Vous devez d'abord choisir le type de logement
                  </option>
                  <option
                    v-for="(subType, cle) in sousTypes"
                    :key="cle"
                    :value="subType.idSousType"
                  >
                    {{ subType.libelleSousType }}
                  </option>
                </select>
              </div>
              <div class="input-item">
                <span>Superficie* ( en m² )</span>
                <input
                  type="number"
                  min="0"
                  required
                  placeholder="ex: 150m²"
                  v-model="logement.superficie"
                />
              </div>
              <div class="input-item">
                <span>Réference du logement*</span>
                <input
                  type="text"
                  placeholder="Une reference est propre à chaque logement. Ex: LT004"
                  ref="refLogement"
                />
              </div>
              <div class="input-item">
                <span>Loyer minimal*</span>
                <input
                  type="number"
                  min="0"
                  required
                  placeholder="Entrez le montant minimal"
                  v-model="logement.prixMin"
                />
              </div>
              <div class="input-item">
                <span>Loyer maximal*</span>
                <input
                  type="number"
                  min="0"
                  required
                  placeholder="Entrez le montant maximal"
                  v-model="logement.prixMax"
                />
              </div>
              <div class="input-item">
                <span>Une description du logement (facultatif)</span>
                <textarea
                  required
                  placeholder="Entrez une brève description du logement"
                  v-model="logement.description"
                ></textarea>
              </div>
              <div class="input-item">
                <span>Votre logement est-il associé à un batiment ?</span>
                <select
                  v-model="idBatiment"
                  @change="selectAdresseBatiment(idBatiment)"
                >
                  <option :value="null" disabled>
                    Choisir si votre logement est lié à un batiment
                  </option>
                  <option v-if="idBatiment !== null" :value="null">
                    N'appartient à aucun batiment
                  </option>
                  <option
                    v-for="(bat, cle) in tousLesBatiments"
                    :key="cle"
                    :value="bat.idBatiment"
                    class="batiment-option"
                  >
                    <span class="bat-name">
                      {{ bat.nomBatiment }}
                    </span>
                  </option>
                </select>
              </div>
            </div>
          </section>
          <section
            v-show="step == secondstep"
            class="process-item configuration"
          >
            <div class="config-group">
              <div class="config-item">
                <span class="title-item">
                  Chambre<span v-show="logement.nbchambre > 1">s</span>
                </span>
                <div class="config-controller">
                  <div class="control-btn" @click="configAdd('remove', 'chbr')">
                    -
                  </div>
                  <span class="item-value">{{ logement.nbchambre }}</span>
                  <div class="control-btn" @click="configAdd('add', 'chbr')">
                    +
                  </div>
                </div>
              </div>

              <div class="barre"></div>
              <div class="config-item">
                <span class="title-item">
                  Douche<span v-show="logement.nbdouche > 1">s</span>
                </span>
                <div class="config-controller">
                  <div class="control-btn" @click="configAdd('remove', 'dche')">
                    -
                  </div>
                  <span class="item-value">{{ logement.nbdouche }}</span>
                  <div class="control-btn" @click="configAdd('add', 'dche')">
                    +
                  </div>
                </div>
              </div>
            </div>
            <div class="config-group">
              <div class="config-item">
                <span class="title-item">
                  Cuisine<span v-show="logement.nbcuisine > 1">s</span>
                </span>
                <div class="config-controller">
                  <div class="control-btn" @click="configAdd('remove', 'cse')">
                    -
                  </div>
                  <span class="item-value">{{ logement.nbcuisine }}</span>
                  <div class="control-btn" @click="configAdd('add', 'cse')">
                    +
                  </div>
                </div>
              </div>
              <div class="barre"></div>
              <div class="config-item">
                <span class="title-item">
                  Salon<span v-show="logement.nbsalon > 1">s</span>
                </span>
                <div class="config-controller">
                  <div class="control-btn" @click="configAdd('remove', 'sln')">
                    -
                  </div>
                  <span class="item-value">{{ logement.nbsalon }}</span>
                  <div class="control-btn" @click="configAdd('add', 'sln')">
                    +
                  </div>
                </div>
              </div>
            </div>
            <div class="config-group">
              <div class="config-item">
                <span class="title-item">
                  Buanderie<span v-show="logement.nbbuanderie > 1">s</span>
                </span>
                <div class="config-controller">
                  <div class="control-btn" @click="configAdd('remove', 'bdr')">
                    -
                  </div>
                  <span class="item-value">{{ logement.nbbuanderie }}</span>
                  <div class="control-btn" @click="configAdd('add', 'bdr')">
                    +
                  </div>
                </div>
              </div>
              <div class="barre"></div>
              <div class="config-item">
                <span class="title-item">
                  Parking<span v-show="logement.nbparking > 1">s</span>
                </span>
                <div class="config-controller">
                  <div class="control-btn" @click="configAdd('remove', 'pkg')">
                    -
                  </div>
                  <span class="item-value">{{ logement.nbparking }}</span>
                  <div class="control-btn" @click="configAdd('add', 'pkg')">
                    +
                  </div>
                </div>
              </div>
            </div>
            <div class="barre-h"></div>
            <div class="other-config-container">
              <span>D'autres caractéristiques spéciales?</span>
              <div class="other-config">
                <select @change="extraConfig()" v-model="extraValue">
                  <option :value="null" disabled>
                    Choisir une caracteristique
                  </option>
                  <option value="jacuzzi">Jacuzzi</option>
                  <option value="magasin">Magasin</option>
                  <option value="magasin">Sona</option>
                  <option value="piscine">Piscine</option>
                </select>
                <input
                  type="number"
                  placeholder="quantité"
                  v-model="extraQuantity"
                />
              </div>
            </div>
          </section>
          <section v-show="step == thirdstep" class="process-item location">
            <div class="map-area">
              <div class="map-container" ref="carte"></div>
              <div class="research-input" id="location">
                <div>
                  <i class="ik ik-search"></i>
                </div>
                <input
                  type="text"
                  id="location-input"
                  ref="location"
                  placeholder="Entrez la zone de localisation"
                />
              </div>
            </div>
            <div class="input-container">
              <div class="input-item">
                <span>Pays</span>
                <select v-model="logement.pays">
                  <option
                    v-for="(country, cle) in tousLesPays"
                    :key="cle"
                    :value="country.libellePays"
                  >
                    {{ country.libellePays }}
                  </option>
                </select>
              </div>
              <div class="input-item">
                <span>Ville</span>
                <input
                  type="text"
                  placeholder="Yaoundé"
                  v-model="logement.ville"
                />
              </div>
              <div class="input-item">
                <span>Quartier</span>
                <input
                  type="text"
                  min="0"
                  placeholder="ex: Mvan"
                  v-model="logement.quartier"
                />
              </div>
              <div class="more-location">
                <div style="margin-bottom: 20px">
                  <input type="checkbox" name="" id="" />
                  <span> Plus de critères </span>
                </div>
                <div class="semi-container" style="display: none">
                  <div class="input-item">
                    <span>Lattitude</span>
                    <input
                      type="number"
                      min="0"
                      placeholder="0.000000000000000"
                    />
                  </div>
                  <div class="input-item">
                    <span>Longitude</span>
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0000000000000000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section v-show="step == lastStep" class="process-item photos">
            <span>
              Ajouter des images de très bonnes qualités. <br />
              Elles seront utilisées lorsque vous créerez des annonces sur ce
              logement. <br />
              <b>Minimum 5 images</b> <br />
              c'est facultatif
            </span>
            <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              :data-images="images"
              :dragText="$t('data.logement_dragText')"
              :browseText="$t('data.logement_browseText')"
              idUpload="myIdUpload"
              editUpload="myIdEdit"
            ></vue-upload-multiple-image>
          </section>
        </div>
        <!-- <div class="form-footer">
          <div class="cancel" @click="close()" v-show="step == 0">
            <OutlinedDarkBtn buttonTitle="Annuler" />
          </div>
          <div class="cancel" @click="back()" v-show="step > 0">
            <OutlinedDarkBtn buttonTitle="Précedent" />
          </div>
          <div class="validate" v-show="step !== lastStep" @click="nextStep()">
            <FullPinkBtn buttonTitle="Suivant" />
          </div>
          <div
            class="validate"
            @click="onComplete('add')"
            v-show="step == lastStep"
          >
            <FullPinkBtn buttonTitle="Créer le logement" />
          </div>
        </div> -->
      </b-overlay>
      <div class="form-footer" style="background-color: white; display: flex;justify-content: space-between;margin-top: 0%;padding: 2%;">
          <div class="cancel" @click="close()" v-show="step == 0">
            <OutlinedDarkBtn buttonTitle="Annuler" />
          </div>
          <div class="cancel" @click="back()" v-show="step > 0">
            <OutlinedDarkBtn buttonTitle="Précedent" />
          </div>
          <div class="validate" v-show="step !== lastStep" @click="nextStep()">
            <FullPinkBtn buttonTitle="Suivant" />
          </div>
          <div
            class="validate"
            @click="onComplete('add')"
            v-show="step == lastStep"
          >
            <FullPinkBtn buttonTitle="Créer le logement" />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import notif from "@/plugins/notif.js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
const php = require("phpjs");
export default {
  components: {
    VueUploadMultipleImage,
    FullPinkBtn,
    OutlinedDarkBtn,
  },
  props: {
    batiment: { type: Object },
    editLogement: { type: Object },
    action: { type: String },
  },
  data: () => ({
    step: 0,
    firststep: 0,
    secondstep: 1,
    thirdstep: 2,
    lastStep: 3,
    showOverlay: false,
    temoin: false,
    incompleteData: false,
    customErrorMsg: `Veuillez renseigner toutes les informations nécessaires.`,
    libelle: "",
    valeur: "",
    refLog: "",
    logement: {
      reference: "",
      superficie: null,
      description: "",
      prixMin: null,
      prixMax: null,
      pays: "Cameroun",
      ville: "",
      quartier: "",
      lat: null,
      lon: null,
      superficie: null,
      nbchambre: 0,
      nbcuisine: 0,
      nbsalon: 0,
      nbdouche: 0,
      nbparking: 0,
      nbpiscine: 0,
      nbgarage: 0,
      nbbuanderie: 0,
      nbsona: 0,
    },

    map: null,
    myCoordinates: {
      lat: 0,
      lng: 0,
    },
    owooh: "",
    zoom: 17,
    telUser: null,
    passUser: null,
    avatarSmUser: null,
    extraValue: null,
    extraQuantity: null,
    type: null,
    sousType: null,
    typesLogement: [],
    sousTypesLogements: [],
    sousTypes: [],
    tousLesBatiments: [],
    idBatiment: null,
    images: [],
    photos: [],
    markers: [],
    valeurPays: "Cameroun",
    tousLesPays: [
      {
        libellePays: "Afghanistan",
      },
      {
        libellePays: "Afrique du Sud",
      },
      {
        libellePays: "Albanie",
      },
      {
        libellePays: "Algérie",
      },
      {
        libellePays: "Allemagne",
      },
      {
        libellePays: "Andorre",
      },
      {
        libellePays: "Angola",
      },
      {
        libellePays: "Anguilla",
      },
      {
        libellePays: "Antarctique",
      },
      {
        libellePays: "Antigua-et-Barbuda",
      },
      {
        libellePays: "Antilles néerlandaises",
      },
      {
        libellePays: "Arabie saoudite",
      },
      {
        libellePays: "Argentine",
      },
      {
        libellePays: "Arménie",
      },
      {
        libellePays: "Aruba",
      },
      {
        libellePays: "Australie",
      },
      {
        libellePays: "Autriche",
      },
      {
        libellePays: "Azerbaïdjan",
      },
      {
        libellePays: "Bahamas",
      },
      {
        libellePays: "Bahreïn",
      },
      {
        libellePays: "Bangladesh",
      },
      {
        libellePays: "Barbade",
      },
      {
        libellePays: "Bélarus",
      },
      {
        libellePays: "Belgique",
      },
      {
        libellePays: "Belize",
      },
      {
        libellePays: "Bénin",
      },
      {
        libellePays: "Bermudes",
      },
      {
        libellePays: "Bhoutan",
      },
      {
        libellePays: "Bolivie",
      },
      {
        libellePays: "Bosnie-Herzégovine",
      },
      {
        libellePays: "Botswana",
      },
      {
        libellePays: "Brésil",
      },
      {
        libellePays: "Brunéi Darussalam",
      },
      {
        libellePays: "Bulgarie",
      },
      {
        libellePays: "Burkina Faso",
      },
      {
        libellePays: "Burundi",
      },
      {
        libellePays: "Cambodge",
      },
      {
        libellePays: "Cameroun",
      },
      {
        libellePays: "Canada",
      },
      {
        libellePays: "Cap-Vert",
      },
      {
        libellePays: "Ceuta et Melilla",
      },

      {
        libellePays: "Chili",
      },
      {
        libellePays: "Chine",
      },
      {
        libellePays: "Chypre",
      },
      {
        libellePays: "Colombie",
      },
      {
        libellePays: "Comores",
      },
      {
        libellePays: "Congo-Brazzaville",
      },
      {
        libellePays: "Corée du Nord",
      },
      {
        libellePays: '"Corée du Sud',
      },
      {
        libellePays: "Costa Rica",
      },
      {
        libellePays: "Côte d’Ivoire",
      },
      {
        libellePays: "Croatie",
      },
      {
        libellePays: "Cuba",
      },
      {
        libellePays: "Danemark",
      },
      {
        libellePays: "Diego Garcia",
      },
      {
        libellePays: "Djibouti",
      },
      {
        libellePays: "Dominique",
      },
      {
        libellePays: "Égypte",
      },
      {
        libellePays: "El Salvador",
      },
      {
        libellePays: "Émirats arabes unis",
      },
      {
        libellePays: "Équateur",
      },
      {
        libellePays: "Érythrée",
      },
      {
        libellePays: "Espagne",
      },
      {
        libellePays: "Estonie",
      },
      {
        libellePays: "État de la Cité du Vatican",
      },

      {
        libellePays: "États fédérés de Micronésie",
      },

      {
        libellePays: "États-Unis",
      },
      {
        libellePays: "Éthiopie",
      },
      {
        libellePays: "Fidji",
      },
      {
        libellePays: "Finlande",
      },
      {
        libellePays: "France",
      },
      {
        libellePays: "Gabon",
      },
      {
        libellePays: "Gambie",
      },
      {
        libellePays: "Géorgie",
      },
      {
        libellePays: "Géorgie du Sud et les îles Sandwich du Sud",
      },
      {
        libellePays: "Ghana",
      },
      {
        libellePays: "Gibraltar",
      },
      {
        libellePays: "Grèce",
      },
      {
        libellePays: "Grenade",
      },
      {
        libellePays: "Groenland",
      },
      {
        libellePays: "Guadeloupe",
      },
      {
        libellePays: "Guam",
      },
      {
        libellePays: "Guatemala",
      },
      {
        libellePays: "Guernesey",
      },
      {
        libellePays: "Guinée",
      },
      {
        libellePays: "Guinée équatoriale",
      },
      {
        libellePays: "Guinée-Bissau",
      },
      {
        libellePays: "Guyana",
      },
      {
        libellePays: "Guyane française",
      },
      {
        libellePays: "Haïti",
      },
      {
        libellePays: "Honduras",
      },
      {
        libellePays: "Hongrie",
      },

      {
        libellePays: "Île Bouvet",
      },
      {
        libellePays: "Île Christmas",
      },
      {
        libellePays: "Île Clipperton",
      },
      {
        libellePays: "Île de l'Ascension",
      },
      {
        libellePays: "Île de Man",
      },
      {
        libellePays: "Île Norfolk",
      },
      {
        libellePays: "Îles Åland",
      },
      {
        libellePays: "Îles Caïmans",
      },
      {
        libellePays: "Îles Canaries",
      },
      {
        libellePays: "Îles Cocos - Keeling",
      },
      {
        libellePays: "Îles Cook",
      },
      {
        libellePays: "Îles Féroé",
      },
      {
        libellePays: "Îles Heard et MacDonald",
      },
      {
        libellePays: "Îles Malouines",
      },
      {
        libellePays: "Îles Mariannes du Nord",
      },
      {
        libellePays: "Îles Marshall",
      },
      {
        libellePays: "Îles Mineures Éloignées des États-Unis",
      },
      {
        libellePays: "Îles Salomon",
      },
      {
        libellePays: "Îles Turks et Caïques",
      },
      {
        libellePays: "Îles Vierges britanniques",
      },
      {
        libellePays: "Îles Vierges des États-Unis",
      },
      {
        libellePays: "Inde",
      },
      {
        libellePays: "Indonésie",
      },
      {
        libellePays: "Irak",
      },
      {
        libellePays: "Iran",
      },
      {
        libellePays: "Irlande",
      },
      {
        libellePays: "Islande",
      },
      {
        libellePays: "Israël",
      },
      {
        libellePays: "Italie",
      },
      {
        libellePays: "Jamaïque",
      },
      {
        libellePays: "Japon",
      },
      {
        libellePays: "Jersey",
      },
      {
        libellePays: "Jordanie",
      },
      {
        libellePays: "Kazakhstan",
      },
      {
        libellePays: "Kenya",
      },
      {
        libellePays: "Kirghizistan",
      },
      {
        libellePays: "Kiribati",
      },
      {
        libellePays: "Koweït",
      },
      {
        libellePays: "Laos",
      },
      {
        libellePays: "Lesotho",
      },
      {
        libellePays: "Lettonie",
      },
      {
        libellePays: "Liban",
      },
      {
        libellePays: "Libéria",
      },
      {
        libellePays: "Libye",
      },
      {
        libellePays: "Liechtenstein",
      },
      {
        libellePays: "Lituanie",
      },
      {
        libellePays: "Luxembourg",
      },
      {
        libellePays: "Macédoine",
      },
      {
        libellePays: "Madagascar",
      },
      {
        libellePays: "Malaisie",
      },
      {
        libellePays: "Malawi",
      },
      {
        libellePays: "Maldives",
      },
      {
        libellePays: "Mali",
      },
      {
        libellePays: "Malte",
      },
      {
        libellePays: "Maroc",
      },
      {
        libellePays: "Martinique",
      },
      {
        libellePays: "Maurice",
      },
      {
        libellePays: "Mauritanie",
      },
      {
        libellePays: "Mayotte",
      },
      {
        libellePays: "Mexique",
      },

      {
        libellePays: "Moldavie",
      },
      {
        libellePays: "Monaco",
      },

      {
        libellePays: "Mongolie",
      },

      {
        libellePays: "Monténégro",
      },
      {
        libellePays: "Montserrat",
      },
      {
        libellePays: "Mozambique",
      },
      {
        libellePays: "Myanmar",
      },
      {
        libellePays: "Namibie",
      },
      {
        libellePays: "Nauru",
      },
      {
        libellePays: "Népal",
      },
      {
        libellePays: "Nicaragua",
      },
      {
        libellePays: "Niger",
      },
      {
        libellePays: "Nigéria",
      },
      {
        libellePays: "Niue",
      },
      {
        libellePays: "Norvège",
      },
      {
        libellePays: "Nouvelle-Calédonie",
      },
      {
        libellePays: "Nouvelle-Zélande",
      },
      {
        libellePays: "Oman",
      },
      {
        libellePays: "Ouganda",
      },
      {
        libellePays: "Ouzbékistan",
      },
      {
        libellePays: "Pakistan",
      },
      {
        libellePays: "Palaos",
      },
      {
        libellePays: "Panama",
      },
      {
        libellePays: "Papouasie-Nouvelle-Guinée",
      },
      {
        libellePays: "Paraguay",
      },
      {
        libellePays: "Pays-Bas",
      },
      {
        libellePays: "Pérou",
      },
      {
        libellePays: "Philippines",
      },
      {
        libellePays: "Pitcairn",
      },
      {
        libellePays: "Pologne",
      },
      {
        libellePays: "Polynésie française",
      },
      {
        libellePays: "Porto Rico",
      },
      {
        libellePays: "Portugal",
      },
      {
        libellePays: "Qatar",
      },
      {
        libellePays: "R.A.S. chinoise de Hong Kong",
      },
      {
        libellePays: "R.A.S. chinoise de Macao",
      },
      {
        libellePays: "régions éloignées de l’Océanie",
      },
      {
        libellePays: "République centrafricaine",
      },
      {
        libellePays: "République démocratique du Congo",
      },
      {
        libellePays: "République dominicaine",
      },
      {
        libellePays: "République tchèque",
      },
      {
        libellePays: "Réunion",
      },
      {
        libellePays: "Roumanie",
      },
      {
        libellePays: "Royaume-Uni",
      },
      {
        libellePays: "Russie",
      },
      {
        libellePays: "Rwanda",
      },
      {
        libellePays: "Sahara occidental",
      },
      {
        libellePays: "Saint-Barthélémy",
      },
      {
        libellePays: "Saint-Kitts-et-Nevis",
      },
      {
        libellePays: "SM Saint-Marin",
      },
      {
        libellePays: "MF Saint-Martin",
      },
      {
        libellePays: "Saint-Pierre-et-Miquelon",
      },
      {
        libellePays: "Saint-Vincent-et-les Grenadines",
      },
      {
        libellePays: "Sainte-Hélène",
      },
      {
        libellePays: "Sainte-Lucie",
      },
      {
        libellePays: "Samoa",
      },
      {
        libellePays: "Samoa américaines",
      },
      {
        libellePays: "Sao Tomé-et-Principe",
      },
      {
        libellePays: "Sénégal",
      },
      {
        libellePays: "Serbie",
      },
      {
        libellePays: "Serbie-et-Monténégro",
      },
      {
        libellePays: "Seychelles",
      },
      {
        libellePays: "Sierra Leone",
      },
      {
        libellePays: "Singapour",
      },
      {
        libellePays: "Slovaquie",
      },
      {
        libellePays: "Slovénie",
      },
      {
        libellePays: "Somalie",
      },
      {
        libellePays: "Soudan",
      },
      {
        libellePays: "Sri Lanka",
      },
      {
        libellePays: "Suède",
      },
      {
        libellePays: "Suisse",
      },
      {
        libellePays: "Suriname",
      },
      {
        libellePays: "Svalbard et Île Jan Mayen",
      },
      {
        libellePays: "Swaziland",
      },
      {
        libellePays: "Syrie",
      },
      {
        libellePays: "Tadjikistan",
      },
      {
        libellePays: "Taïwan",
      },
      {
        libellePays: "Tanzanie",
      },
      {
        libellePays: "Tchad",
      },
      {
        libellePays: "Terres australes françaises",
      },
      {
        libellePays: "Territoire britannique de l'océan Indien",
      },
      {
        libellePays: "Territoire palestinien",
      },
      {
        libellePays: "Thaïlande",
      },
      {
        libellePays: "Timor oriental",
      },
      {
        libellePays: "Togo",
      },
      {
        libellePays: "Tokelau",
      },
      {
        libellePays: "Tonga",
      },
      {
        libellePays: "Trinité-et-Tobago",
      },
      {
        libellePays: "Tristan da Cunha",
      },
      {
        libellePays: "Tunisie",
      },
      {
        libellePays: "Turkménistan",
      },
      {
        libellePays: "Turquie",
      },
      {
        libellePays: "Tuvalu",
      },
      {
        libellePays: "Ukraine",
      },
      {
        libellePays: "Union européenne",
      },
      {
        libellePays: "Uruguay",
      },
      {
        libellePays: "Vanuatu",
      },
      {
        libellePays: "Venezuela",
      },
      {
        libellePays: "Viêt Nam",
      },
      {
        libellePays: "Wallis-et-Futuna",
      },
      {
        libellePays: "Yémen",
      },
      {
        libellePays: "Zambie",
      },
      {
        libellePays: "Zimbabwe",
      },
    ],
    disabled: false,
    showSelectBatiment: false,
    //cette propriété est utilisée pour controler la bouton de vaidatation lors de l'edit
    indexForm: 1,
    editCaracteristiques: [],
    caracteristiques: ["Jacousi", "Piscine", "Sona", "Buanderie"],

    map: {},
    mark: {},
    geocoder: {},
    lat: null,
    lng: null,
    initialCoordinates: null,
    zoomLevel: 8,
  }),
  watch: {
    idBatiment() {
      // if (this.idBatiment == null) return;
      // if (this.idBatiment !== null) this.lastStep--;
      {
        //fonction de navigation du menu
        if (this.idBatiment == null) {
          if (this.lastStep == 3) {
            return;
          } else {
            this.lastStep = 3;
            this.thirdstep = 2;
            return;
          }
        } else if (this.idBatiment !== null) {
          // dans le cas où le logement appartient à un batiment, la partie localisation disparait par consequent l'ordre des steps est modifié
          if (this.lastStep - 1 == 3) {
            return;
          } else {
            this.lastStep--;
            this.thirdstep = 10;
          }
        }
      }
    },
  },
  computed: {
    // mapCoordinates() {
    //   if (!this.map) {
    //     return {
    //       lat: 0,
    //       lng: 0,
    //     };
    //   }

    //   return {
    //     lat: this.map
    //       .getCenter()
    //       .lat()
    //       .toFixed(14),
    //     lng: this.map
    //       .getCenter()
    //       .lng()
    //       .toFixed(14),
    //   };
    // },
    repeaterId() {
      return `repeat-added-logement-form-${
        php.empty(this.logement) ? php.uniqid() : this.logement.idLogement
      }`;
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.step = 0;
      this.resetModal();
    },
    back() {
      if (this.step == 0) {
        this.step = 0;
        return;
      }
      this.step--;
      console.log(this.step);
    },
    nextStep() {
      console.log("etape 0");
      this.logement.reference = this.$refs.refLogement.value;
      if (this.step == 0) {
        console.log("etape 1");
        
        if (
          (this.logement.reference =
            "" ||
            this.logement.superficie == null ||
            this.logement.superficie <= 3 ||
            this.logement.prixMin <= 10 ||
            this.type == null ||
            this.sousType == null ||
            this.logement.prixMax <= 10)
        ) {
          //verification des infos de la 1ere etape
          this.customErrorMsg = `veuillez renseigner toutes les informations nécessaires.`;
          this.incompleteData = true;
          setTimeout(() => {
            this.incompleteData = false;
          }, 2000);
        } else if (this.logement.description.length < 40) {
          //verification des infos de la 1ere etape
          this.customErrorMsg = `La description doit contenir au moins 40 caractères!`;
          this.incompleteData = true;
          setTimeout(() => {
            this.incompleteData = false;
          }, 2000);
        } else {
          // validation step suivant si tout est correct
          this.step++;
          return;
        }
        return;
      }
      if (this.step == 1) {
        this.step++;
        return;
      }
      if (this.step == 2) {
        this.step++;
        return;
      }
    },
    resetModal() {
      this.logement = {
        reference: null,
        description: null,
        prixMin: 0,
        prixMax: 0,
        pays: null,
        ville: null,
        quartier: null,
        lat: null,
        lon: null,
        nbchambre: null,
        nbcuisine: null,
        nbsalon: null,
        nbdouche: null,
        nbparking: null,
        nbpiscine: null,
        nbgarage: null,
        nbsona: null,
        superficie: null,
      };
      this.idSousType = null;
      this.idBatiment = null;
      this.photos = [];
      this.refLog = "";
    },
    //fonction pour la configuration des pièces du logement
    configAdd(action, variable) {
      if (action == "add") {
        //ajout d'une unite à la pièce
        if (variable == "chbr") {
          this.logement.nbchambre++;
        }
        if (variable == "dche") {
          this.logement.nbdouche++;
        }
        if (variable == "cse") {
          this.logement.nbcuisine++;
        }
        if (variable == "sln") {
          this.logement.nbsalon++;
        }
        if (variable == "pkg") {
          this.logement.nbparking++;
        }
        if (variable == "bdr") {
          this.logement.nbbuanderie++;
        }
      } else {
        //retrait d'une unite à la pièce
        if (variable == "chbr") {
          if (this.logement.nbchambre == 0) {
            //bloquer le retrait à 0 pour eviter les valeurs negatives
            this.logement.nbchambre = 0;
          } else {
            this.logement.nbchambre--;
          }
        }
        if (variable == "dche") {
          if (this.logement.nbdouche == 0) {
            this.logement.nbdouche = 0;
          } else {
            this.logement.nbdouche--;
          }
        }
        if (variable == "cse") {
          if (this.logement.nbcuisine == 0) {
            this.logement.nbcuisine = 0;
          } else {
            this.logement.nbcuisine--;
          }
        }
        if (variable == "sln") {
          if (this.logement.nbsalon == 0) {
            this.logement.nbsalon = 0;
          } else {
            this.logement.nbsalon--;
          }
        }
        if (variable == "pkg") {
          if (this.logement.nbparking == 0) {
            this.logement.nbparking = 0;
          } else {
            this.logement.nbparking--;
          }
        }
        if (variable == "bdr") {
          if (this.logement.nbbuanderie == 0) {
            this.logement.nbbuanderie = 0;
          } else {
            this.logement.nbbuanderie--;
          }
        }
      }
    },
    extraConfig() {
      console.log(this.extraValue);
      if (this.extraValue == "piscine") {
        this.nbsona = this.extraQuantity;
      } else if (this.extraValue == "sona") {
        this.nbpiscine = this.extraQuantity;
      }
    },
    async getLogementsData() {
      if (!this.$store.state.typesLogements) {
        this.typesLogement = this.$store.state.typesLogements;
      } else {
        try {
          this.typesLogement = await axios
            .get("types-logements?all=true")
            .then((response) => response.result);
        } catch (error) {
          notif.error(error.message);
        }
      }
      if (!this.$store.state.sousTypesLogements) {
        this.sousTypesLogements = this.$store.state.sousTypesLogements;
      } else {
        try {
          this.sousTypesLogements = await axios
            .get("soustypes-logements")
            .then((response) => response.result);
        } catch (error) {
          notif.error(error.message);
        }
      }
      this.showOverlay = false;
    },
    //recupération de tous les batiments
    async getAllBatiments() {
      await axios
        .get("batiments")
        .then((response) => (this.tousLesBatiments = response.result || []))
        .catch((error) => {
          console.log(error.message);
        });
      console.log(this.tousLesBatiments);
    },
    selectAdresseBatiment(idBatiment) {
      const batiment = this.tousLesBatiments.find(
        (elt) => elt.idBatiment == idBatiment
      );
      if (batiment) {
        if (!php.empty(batiment.adresse)) {
          this.logement.pays = batiment.adresse.pays;
          this.logement.ville = batiment.adresse.ville;
          this.logement.quartier = batiment.adresse.quartier;
          this.logement.lon = batiment.adresse.lon;
          this.logement.lat = batiment.adresse.lat;
        }
      }
    },
    //chargement des catégories en focntion du type de logement sélectionné
    changeType(a) {
      let data = [];
      for (let i = 0; i < this.sousTypesLogements.length; i++) {
        data.push(this.sousTypesLogements[i]);
      }
      console.log(this.type);
      let result = data.filter((x) => x.idType == this.type.idType);
      this.sousTypes = result;
      console.log("sous-type", this.sousTypes);
    },
    uploadImageSuccess(formData, index, fileList) {
      this.photos = [];
      console.log("taille Totale", fileList.length - 1, "formaData", formData);
      for (let i = 0; i < fileList.length; i++) {
        //console.log("essai",fileList[i].path)
        this.photos.push(fileList[i].path);
      }
      console.log("photosTaille", this.photos.length);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("voulez vous supprimer cette image");
      if (r == true) {
        done();
      } else {
        console.log("erreur");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", fileList);
    },

    onComplete(action) {
      console.log(this.logement);
      let add = {
        pays: this.logement.pays,
        ville: this.logement.ville,
        quartier: this.logement.quartier,
        lat: this.logement.lat,
        lon: this.logement.lon,
      };
      console.log(this.sousType);
      console.log(this.idBatiment);
      //envoi formulaire création logement
      let data = {
        ref: this.logement.reference,
        superficie: this.logement.superficie,
        description: this.logement.description,
        prixMin: this.logement.prixMin,
        prixMax: this.logement.prixMax,
        idSousType: this.sousType,
        idBatiment: this.idBatiment,
        adresse: add,
        photos: this.photos,
        caracteristiques: [
          {
            libelle: "chambre",
            valeur: this.logement.nbchambre,
          },
          {
            libelle: "salon",
            valeur: this.logement.nbsalon,
          },
          {
            libelle: "cuisine",
            valeur: this.logement.nbcuisine,
          },
          {
            libelle: "douche",
            valeur: this.logement.nbdouche,
          },
          {
            libelle: "parking",
            valeur: this.logement.nbparking,
          },
        ],
      };

      if (action == "add") {
        this.showOverlay = true;
        if (this.logement.description.length > 255) {
          this.showOverlay = false;
          return App.alertError("La description ne dois pas dépasser 255 caractères.");
        }
        axios
          .post("logements", data)
          .then((response) => {
            this.resetModal();
            this.showOverlay = false;
            storage.set("logements", null);
            this.$emit("update", response.result);
            this.close();
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.showOverlay = false;
            notif.error(error.message);
          });
      }
      if (action == "edit") {
        for (let i = 0; i < this.editCaracteristiques.length; i++) {
          data.caracteristiques.push(this.editCaracteristiques[i]);
        }
        console.log("caractéristiques à envoyer", data.caracteristiques);
        axios
          .put(`logements/${this.editLogement.idLogement}`, data)
          .then((response) => {
            if (!response.success) {
              this.showOverlay = false;
              return App.alertError(response.message);
            }
            this.resetModal();
            this.showOverlay = false;
            this.editLogement = null;
            storage.set("logements", null);
            this.$emit("editSuccessfull", response.result);
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.showOverlay = false;
            notif.error(error.message);
          });
      }
    },
    initMap() {
      this.initialCoordinates = new google.maps.LatLng(3.866667, 11.516667);
      this.zoomLevel = 12;
      var mapOptions = {
        zoom: this.zoomLevel,
        center: this.initialCoordinates,
        mapId: "5e906764d63d082e",
        tilt: 45,
        disableDefaultUI: true,
      };
      var bounds = {
        north: 3.866667599,
        south: 3.86,
        east: 11.51,
        west: 11.5,
      };
      this.map = new google.maps.Map(this.$refs.carte, mapOptions);
      this.marker = new google.maps.Marker({
        position: this.map.getCenter(),
        map: this.map,
        title: "point de repère",
        animation: google.maps.Animation.DROP,
        draggable: false,
      });

      // form-research
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById("location")
      );

      const input = document.getElementById("location-input");
      const options = {
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.bindTo("bounds", this.map);

      autocomplete.addListener("place_changed", () => {
        this.marker.setVisible(false);

        const place = autocomplete.getPlace();

        console.log(place);
        this.logement.lon = place.geometry.location.lng();
        this.logement.lat = place.geometry.location.lat();
        this.initialCoordinates = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        this.logement.ville = place.address_components[1].long_name;
        this.logement.quartier = place.name;
        this.location = this.$refs.location.value;
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }

        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);
      });

      //centrer le marker lorsque l'utilisateur bouge la carte
      let vm = this;
      google.maps.event.addListener(this.map, "center_changed", function () {
        // 0.1 seconds after the center of the map has changed,
        // set back the marker position.
        globalThis.setTimeout(function () {
          var center = vm.map.getCenter();
          vm.logement.lon = center.lng();
          vm.logement.lat = center.lat();
          //console.log(center);
          console.log(vm.logement.lon);
          vm.marker.setPosition(center);
        }, 100);
      });
    },
  },
  async mounted() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute("defer", "true");
    googleScript.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB4wnGV-GeVMiv-_yT2qO_iKyaM_bMGX7w&libraries=places"
    );
    document.head.appendChild(googleScript);
    setTimeout(() => {
      this.initMap();
    }, 1500);
    await this.getAllBatiments();
    await this.getLogementsData();

    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      // Do something with the position
      console.log(latitude, longitude);
    };

    const error = (err) => {
      console.log(error);
    };
    navigator.geolocation.getCurrentPosition(success, error);
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-size: 1rem;
}
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
.parent-form-container{
  height: 90%;
  max-height: 700px;
  width: 70%;
  max-width: 900px;
  background: white;
    /* border-radius: 8px; */
  cursor: auto;

}
.parent-form-container .form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: auto;
}
.form-container header {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid #eee;
}
.form-container header .header-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.header-part span {
  font-weight: bold;
  font-size: 1.5rem;
}
.header-part .clos {
  font-size: 1.8rem;
  color: #191c22;
  cursor: pointer;
}
header .progress-area {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.progress-area .step-item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: auto;
  height: auto;
  user-select: none;
  cursor: pointer;
}
.step-item span {
  font-weight: 500;
  color: #424242;
}
.step-item span.active {
  color: #f5365c;
}
.step-item .bullet {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #424242;
  overflow: visible;
}
.step-item .bullet span {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: #424242;
}
.step-item .bullet div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #424242;
}
.step-item .bullet div i {
  color: white;
  font-size: 1.2rem;
}
.bullet.active {
  border: 1px solid #f5365c;
}
.bullet.active span {
  color: #f5365c;
}
.step-item .step .bullet:before,
.step-item .step .bullet:after {
  position: absolute;
  content: "";
  bottom: 11px;
  right: -51px;
  height: 3px;
  width: 44px;
  background: #191c22;
}
.form-container .form-footer {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 200px;
  border-top: 1px solid #eee;
  margin-top: 2%;
}
.form-container .main-form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 240px);
}
.main-form .error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 175px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 350px;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.process-item {
  padding: 0 5%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  transition: 0.3s ease all;
}
.basic-data {
  padding: 30px 5% 0;
  width: 100%;
  height: 100%;
}
.process-item.photos {
  padding: 30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.photos span {
  margin-bottom: 50px;
  display: inline-block;
  max-width: 550px;
  text-align: center;
}
.input-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 20px));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  width: 100%;
  height: auto;
}
.title {
  font-weight: 600;
}
textarea {
  border: none;
  border: 1px solid rgb(235, 227, 227);
  padding: 1.5%;
  border-radius: 6px;
  height: 78px;
  resize: none;
  margin-bottom: 100px
}
.input-container .input-item {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.input-item input,
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item select option {
  padding: 10px 0;
  /* height: 45px; */
}
/* .input-item select .batiment-option {
}
.batiment-option .bat-name {
} */
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-container.radio label,
.input-container.index label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45%;
  background: transparent;
  background: #f0f0f0;
  border-radius: 8px;
}
.input-container.radio label.check,
.input-container.index label.check {
  background: #191c22;
  color: white;
}
.input-container.radio label input {
  display: none;
}
.process-item.location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.location .input-container {
  display: flex;
  flex-direction: column;
}
.input-container .more-location {
  height: auto;
  width: 100%;
}
.semi-container {
  /* margin-top: 20px; */
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
}
.semi-container .input-item {
  width: 48%;
}
.process-item.location .map-area {
  padding: 20px 0;
}
.map-area .map-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(187, 187, 187);
}
.map-area .research-input {
  background: white;
  padding-left: 5px;
  margin: 10px;
  display: flex;
  height: 40px;
  width: 93%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.research-input div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border-radius: 8px;
}
.research-input div i {
  font-size: 1.3rem;
  line-height: 0;
}
.research-input input {
  padding-left: 5px;
  height: 100%;
  width: calc(100% - 30px);
  border: none;
  border-radius: 8px;
  background: transparent;
}
.process-item.configuration {
  padding-top: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.process-item.configuration .config-group {
  display: flex;
  align-items: center;
  height: auto;
  width: 85%;
}
.process-item.configuration .config-group .barre {
  margin: 0 20px 15px;
  height: 50%;
  width: 2px;
  background: #eee;
}
.process-item.configuration .barre-h {
  margin: 30px 0;
  height: 1px;
  width: calc(80% + 42px);
  background: #eee;
}
.process-item.configuration .other-config-container {
  height: auto;
  width: 45%;
}
.other-config {
  margin-top: 20px;
  display: flex;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  height: 50px;
}
.other-config select {
  height: 100%;
  width: calc(100% - 100px);
  border-radius: 8px 0 0 8px;
  border: none;
}
.other-config input {
  height: 100%;
  width: 100px;
  border-radius: 0 8px 8px 0;
  border: none;
}
.process-item.configuration .config-item {
  margin-bottom: 15px;
  padding: 0 5px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 47%;
  border-radius: 8px;
  border: 1px solid #eee;
}
.config-item .title-item {
  font-weight: 500;
}
.config-item .config-controller {
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
}
.config-controller .control-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 40px;
  background: #f5f5f5;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  color: #424242;
  transition: all 0.2s ease;
}
.config-controller .control-btn:active {
  transform: scale(0.96);
}
.config-controller .item-value {
  margin: 0 10px;
}
.process-item.end {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.process-item.end .title {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}
.process-item.end p {
  margin-top: 1.2rem;
  text-align: center;
  width: 80%;
  max-width: 600px;
  color: #424242;
}
</style>
