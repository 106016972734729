<template>
  <div class="container-fluid">
    <!--page header start -->
    <div class="row">
      <div class="col-md-12">
        <div class="mb-2 clearfix">
          <div
            class="display-options headder-logement-page"
            id="displayOptions"
          >
            <div class="left-header">
              <!-- <div class="btn-group d-inline-block float-md-left mr-1 mb-1 ">
                
              </div> -->
              <b-form-select
                v-model="filtre_categories"
                style="margin-right:15px;height: 50px; width: 250px; border-radius: 8px; box-shadow: none;"
              >
                <b-form-select-option :value="null">
                  {{ $t("data.Filtre_de_categories") }}
                </b-form-select-option>
                <b-form-select-option-group
                  v-for="(tl, i) in typesLogements"
                  :key="i"
                  :label="tl.libelleType"
                >
                  <b-form-select-option
                    v-for="(stl, j) in tl.sousTypesLogement"
                    :key="j"
                    :value="stl.idSousType"
                    >{{ stl.libelleSousType }}</b-form-select-option
                  >
                </b-form-select-option-group>
              </b-form-select>
              <SearchForm v-model="search" :placeholder="placeholderInput" />
            </div>

            <div class="float-md-right d-flex">
              <paginatorTop
                class="filter-block"
                :offset="offset"
                :libelle="$t('data.logements')"
                :total="logements.length"
                :limit="perPage"
                :page="currentPage"
                @pageChanged="
                  (page) => {
                    currentPage = page;
                  }
                "
                @limitChanged="
                  (limit) => {
                    perPage = limit;
                  }
                "
                v-show="items.length > 5"
              />
              <!-- <btnAdd
                :disabled="!canCreateLogement"
                :message="$t('data.logement_ajouter_logement')"
                v-b-modal.logementForm
              /> -->
              <btnAdd
                :disabled="!canCreateLogement"
                :message="$t('data.logement_ajouter_logement')"
                @click="createLogementVisible = true"
              />

              <!--<b-button
                v-b-tooltip.top="'Télécharger un fichier modèle pour l\'import de logements en masse'"
                v-b-modal.modal-prevent-closing
                @click.prevent="loadCsv"
                class="btn btn-info ml-4 dispose"
                style="height:45px;"
              >
                <i class="fa fa-download"></i>
              </b-button>-->

              <!--traitement import liste de logements-->
              <!--<flatfile-button
                class="mt-2"
                :licenseKey="licenseKey"
                :customer="customer"
                :settings="settings"
                :onData="onData"
                type="logement"
              >Ajout de logements en masse</flatfile-button>-->
            </div>
          </div>
        </div>
        <p class="rappel">
          {{ $t("data.possessions_actuelle") }}
          <span v-if="trueLogements.length" style="color: #f5365c;"
            >{{ trueLogements.length }} {{ $t("data.home_logements") }}.</span
          >
          <span v-else style="color: #f5365c;"
            >{{ trueLogements.length }}
            {{ $t("data.occupation_logement") }}.</span
          >
        </p>
        <div class="separator mb-20"></div>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-alert
            variant="info"
            class="text-center"
            show
            v-if="!logements.length"
          >
            <i class="fa fa-exclamation-triangle fa-3x"></i>
            <br />
            <span class="h4 d-inline-flex ml-2">
              {{ $t("data.logement_pas_de_logement") }}
            </span>
          </b-alert>
          <b-row v-else class="layout-wrap">
            <div v-for="(logement, i) in items" :key="logement.idLogement || i">
              <logement
                @makeClone="cloneLogement"
                @makeUpdate="updateLogement"
                @deleted="removeLogement"
                :logement="logement"
                @showDetails="
                  (logement) => {
                    showLogement(logement, 'details');
                  }
                "
                @showAnalyses="
                  (logement) => {
                    showLogement(logement, 'analyses');
                  }
                "
              />
            </div>
          </b-row>
          <paginator
            hr="top"
            :offset="offset"
            :total="logements.length || 0"
            :limit="perPage"
            :page="currentPage"
            @pageChanged="
              (page) => {
                currentPage = page;
              }
            "
            @limitChanged="
              (limit) => {
                perPage = limit;
              }
            "
            v-show="items.length > 5"
          />
        </b-overlay>
      </div>
    </div>

    <!-- MODALE POUR AFFICHER LES DETAILS D'UN LOGEMENT -->
    <div
      v-if="logement"
      class="modal fade edit-layout-modal"
      id="editLayoutItem"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLayoutItemLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLayoutItemLabel">
              {{
                modalAction == "details"
                  ? $t("data.logement_details_logement")
                  : ""
              }}
              {{
                modalAction == "analyses"
                  ? $t("data.logement_analyses_logement")
                  : ""
              }}
              <b>{{ logement.refLogement }}</b
              >.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <details-logement
              v-if="modalAction == 'details'"
              :idLogement="logement.idLogement"
            />
            <analyses-logement
              v-if="modalAction == 'analyses'"
              :idLogement="logement.idLogement"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="logement">
      <b-modal
        v-model="clone.enabled"
        :title="
          $t('data.logement_cloner_logement') + ' ' + logement.refLogement
        "
        @ok="validateClone"
        @hidden="resetCloneForm"
      >
        <template #modal-footer="{ ok }">
          <b-button
            size="sm"
            variant="danger"
            @click="ok()"
            :disabled="clone.submitted"
          >
            {{ $t("data.logement_valider_logement") }}
            <b-spinner v-if="clone.submitted" small />
          </b-button>
        </template>
        <div class="jumbotron pt-10 pb-10">
          <p>{{ $t("data.logement_description_clonage") }}</p>
        </div>
        <b-form-group
          :description="$t('data.logement_nombre_de_clones')"
          :label="$t('data.logement_nombre_de_clones_label')"
        >
          <b-form-input
            v-model="clone.nbr"
            type="number"
            :min="1"
            :class="{ 'is-invalid': clone.error }"
          />
        </b-form-group>
        <b-form-group
          :description="$t('data.logement_batiment_lie_au_clonage')"
          :label="$t('data.logement_batiment_lie_au_clonage_label')"
        >
          <b-form-select v-model="clone.idBatiment">
            <b-form-select-option :value="null">
              {{ $t("data.logement_pas_de_batiment") }}
            </b-form-select-option>
            <b-form-select-option-group
              v-for="(c, i) in cites"
              :key="i"
              :label="c.nomCite"
            >
              <b-form-select-option
                v-for="(b, j) in c.batiments"
                :key="j"
                :value="b.idBatiment"
                >{{ b.nomBatiment }}</b-form-select-option
              >
            </b-form-select-option-group>
          </b-form-select>
        </b-form-group>
      </b-modal>
    </div>
    <!--MODAL POUR AJOUTER OU MODIFIER UN LOGEMENT-->
    <b-modal
      id="logementForm"
      ref="logement-form"
      size="lg"
      :title="title"
      hide-footer
      no-close-on-backdrop
      hide-header-close
    >
      <template #modal-title>
        <span class="ml-4 text-form-logement">{{ title }}</span>
      </template>
      <div>
        <AddLogement
          @logementAdded="addedLogement"
          @editSuccessfull="editSuccessfull"
          :editLogement="logement"
          :action="action"
          @closeLogementModal="resetLogementFormProps"
        />
      </div>
    </b-modal>

    <load-csv v-if="commandeLoadCsv" />

    <AddLogementForm
      v-if="createLogementVisible"
      @close="createLogementVisible = false"
      @update="(data) => addedLogement(data)"
    />
  </div>
</template>
<script>
import AddLogement from "@/views/logements/LogementForm.vue";
import LoadCsv from "@/views/logements/LoadCsv.vue";
import Logement from "@/components/_patrimoine/Logement.vue";
import DetailsLogement from "@/components/_patrimoine/DetailsLogement.vue";
import AnalysesLogement from "@/components/_patrimoine/AnalysesLogement.vue";
import SearchForm from "@/components/parts/SearchForm.vue";
import AddLogementForm from "@/components/form/ajouterLogementForm.vue";
import { FlatfileButton } from "@flatfile/vuejs";
const php = require("phpjs");
import { mapGetters } from "vuex";

export default {
  name: "logements",
  components: {
    AddLogement,
    Logement,
    DetailsLogement,
    AnalysesLogement,
    FlatfileButton,
    LoadCsv,
    SearchForm,
    AddLogementForm,
  },
  data: () => ({
    createLogementVisible: false,
    userObject: storage.get("userObject"),
    //début données liées à l'import de plusieurs logements
    placeholderInput: "Rechercher un logement",

    licenseKey: "6e79e3c9-a02b-44ba-b761-9f18a885e464",
    settings: {
      disableManualInput: true,
      type: "logement",
      title: "Ajout de logements en masse",
      displayEncoding: false,
      /* theme: {
                global: {
                    backgroundColor: "#212327",
                    textColor: "#c2c3c3",
                    primaryTextColor: "#c2c3c3",
                    secondaryTextColor: "#c2c3c3",
                    successColor: "#c2c3c3",
                    warningColor: "#c2c3c3",
                    fontFamily: "font"
                },
          },*/
      fields: [
        {
          label: "reférence",
          key: "ref",
          validators: [{ validate: "required" }],
        },
        { label: "description", key: "description" },
        {
          label: "Prix Minimal",
          key: "prixMin",
          validators: [{ validate: "required" }],
        },
        {
          label: "Prix Maximal",
          key: "prixMax",
          validators: [{ validate: "required" }],
        },
        {
          label: "Nom Sous-type de logement",
          key: "sousTypeLogement",
          validators: [{ validate: "required" }],
        },
        { label: "Nom Batiment", key: "batiment" },
        { label: "Pays", key: "pays", validators: [{ validate: "required" }] },
        {
          label: "Ville",
          key: "ville",
          validators: [{ validate: "required" }],
        },
        { label: "Quartier", key: "quartier" },
        { label: "Latitude", key: "lat" },
        { label: "Longitude", key: "lon" },
        { label: "Nombre de chambres", key: "nombreChambres" },
        { label: "Nombre de cuisines", key: "nombreCuisines" },
        { label: "Nombre de douches", key: "nombreDouches" },
        { label: "Nombre de salons", key: "nombreSalons" },
        { label: "Nombre de parkings", key: "nombreParkings" },
      ],
    },

    customer: {
      userId: "12345",
    },
    overrides: {
      manual: "Did you know you can type in an import below?",
      dropzone: {
        button: "Click me to upload some data.",
      },
    },

    //fin données liées à l'import de plusieurs logements
    //propriétées, du fichier modèle

    commandeLoadCsv: false,
    search: null,
    showOverlay: true,
    currentPage: 1,
    perPage: 10,

    logements: [],
    trueLogements: [],
    logement: null,

    typesLogements: [],
    filtre_categories: null,
    title: "Ajouter un logement",
    action: "add",
    cites: [],
    clone: {
      enabled: false,
      submitted: false,
      error: false,
      nbr: null,
      idBatiment: null,
    },

    housingToUpload: [],
    permissions: storage.get("userPermissions"),

    modalAction: "",
  }),
  computed: {
    ...mapGetters(["hasAccess"]),
    /**
     * Elements affichés avec prise en charge de la pagination
     */
    items() {
      return php.array_slice(this.logements, this.offset, this.perPage);
    },
    offset() {
      return this.currentPage * this.perPage - this.perPage;
    },
    canCreateLogement() {
      return this.hasAccess("add_logement");
    },
  },
  watch: {
    /**
     * Filtre les logements en fonction du type de logement selectionné
     */
    filtre_categories(value) {
      this.logements = !php.empty(value)
        ? this.trueLogements.filter((elt) => elt.idSousType == value)
        : this.trueLogements;
    },
    search(value) {
      this.logements = !php.empty(value)
        ? this.trueLogements.filter((elt) =>
            elt.refLogement.toLowerCase().includes(value.toLowerCase())
          )
        : this.trueLogements;
    },
  },
  beforeMount() {
    /*setTimeout(() => {
          this.getHousing();
      }, 5000)*/
    this.getHousing();
  },
  mounted() {
    /*this.$root.$on('logement-modifie', (logement) => {
          this.logements = this.renameLogements(this.logements, logement);
          this.trueLogements = this.renameLogements(this.trueLogements, logement);
     }) */
    console.log("=============================");
    let div = document.querySelector(".router-link-active");
    div.style.backgroundColor = "blue !important";
    console.log(div);
    console.log("=============================");
  },
  methods: {
    /**
     * Methode permettant de reinitialiser les props du modal
     */
    resetLogementFormProps() {
      this.action = "add";
      this.title = "Ajouter un logement";
      this.$bvModal.hide("logementForm");
      this.logement = null;
    },
    //traitement de l'évènement émis d'ajout d'un logement
    addedLogement(logement) {
      this.logements = [...this.logements, logement];
      this.trueLogements = [...this.trueLogements, logement];
      this.$bvModal.hide("logementForm");
    },
    editSuccessfull(logement) {
      this.action = "add";
      this.title = "Ajouter un logement";
      this.$bvModal.hide("logementForm");
      this.logement = null;
      this.trueLogements = this.trueLogements.map((elt) => {
        if (elt.idLogement == logement.idLogement) {
          return { ...logement };
        }
        return elt;
      });
      this.logements = this.logements.map((elt) => {
        if (elt.idLogement == logement.idLogement) {
          return { ...logement };
        }
        return elt;
      });
    },
    /**
     * Retire uun batiment
     *
     * @param {Integer} idLogement
     */
    removeLogement(idLogement) {
      this.logements = this.logements.filter(
        (elt) => elt.idLogement != idLogement
      );
      this.trueLogements = this.trueLogements.filter(
        (elt) => elt.idLogement != idLogement
      );
    },

    //recupération de la liste des logements
    async getHousing(begin) {
      this.showOverlay = true;

      this.logements = this.trueLogements = (
        await axios.get("logements").then((response) => response.result || [])
      ).sort((a, b) => {
        if (a.refLogement < b.refLogement) {
          return -1;
        }
        return 1;
      });
      console.log(this.logements);
      this.autoAddTarget();
      this.showOverlay = false;

      if (begin !== false) {
        this.typesLogements = await axios
          .get("types-logements?all=true")
          .then((response) => response.result || []);
        this.cites = (
          await axios.get("cites").then((response) => response.result || [])
        ).filter((elt) => elt.batiments.length > 0);
      }
      console.log(this.logements);
      this.autoDetailsTarget();
    },
    /**
     * Affiche le modal de création d'un= logement directement au chargement de la page
     * ceci est utilisé lorsqu'on est arrivé ici en provenant de la homepage
     */
    autoAddTarget() {
      const formLogement = this.$route.query.formLogement || null;
      if (formLogement) {
        this.$refs["logement-form"].show();
        window.history.replaceState({}, "", window.location.href.split("?")[0]);
      }
    },
    /**
     * Affiche automatiquement les details d'un logement au chargement
     * ceci est utilise dans le cas où on a cliqué sur le batiment en etant sur le details d'une cité
     */
    autoDetailsTarget() {
      const target = this.$route.query.target || null;
      if (target) {
        const logement = this.trueLogements.filter(
          (elt) => elt.idLogement == target
        )[0];
        if (logement) {
          this.showLogement(logement, "details");
          // this.showDetails(logement);
          window.history.replaceState(
            {},
            "",
            window.location.href.split("?")[0]
          );
        }
      }
    },

    /**
     * Ouvre le formulaire de clonnage d'un logement
     */
    cloneLogement(logement) {
      this.clone.enabled = true;
      this.clone.idBatiment = logement.idBatiment;
      this.logement = logement;
    },
    /**
     * valide le clonnage d'un logement
     */
    validateClone(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.clone.error = false;

      if (php.empty(this.clone.nbr) || this.clone.nbr < 1) {
        this.clone.error = true;
        return;
      }
      if (this.clone.nbr > 10) {
        this.clone.error = true;
        return App.error("Impossible de cloner plus de 10 logements");
      }
      this.clone.submitted = true;

      axios
        .post(`logements/${this.logement.idLogement}/clone`, this.clone)
        .then((response) => {
          const clones = (response.result || []).map((elt) => ({
            ...this.logement,
            ...elt,
          }));
          this.logements = [...this.logements, ...clones];
          this.trueLogements = [...this.trueLogements, ...clones];
          this.resetCloneForm();
          return App.notifySuccess(response.message);
        })
        .catch((error) => {
          this.clone.submitted = false;
          return App.alertError(error.message);
        });
    },
    /**
     * Reinitialise le formulaire de clonnage
     */
    resetCloneForm() {
      this.clone = {
        enabled: false,
        submitted: false,
        error: false,
        nbr: null,
        idBatiment: null,
      };
    },

    loadCsv() {
      this.commandeLoadCsv = true;
    },
    updateLogement(logement) {
      this.action = "edit";
      console.log("logement", logement);
      this.logement = logement;
      this.title = "édition du logement " + this.logement.refLogement;
      this.$refs["logement-form"].show();
    },

    /**
     * Affiche les details/analyses d'un logement
     *
     * @param {Object} logement
     */
    showLogement(logement, action = "details") {
      this.logement = logement;
      this.modalAction = action;
      setTimeout(() => {
        $(".modal-backdrop").remove();
        $("#editLayoutItem").modal("show");
        $("#editLayoutItem").on("hide.bs.modal", (e) => {
          this.logement = null;
        });
        $("#editLayoutItem").on("hidden.bs.modal", (e) => {
          this.logement = null;
        });
      }, 100);
    },

    /**
     * Traitement de l'ajout de logements en masse
     */
    onData(result) {
      let errorState = false;

      for (let i = 0; i < result.$data.length; i++) {
        let adresse = {
          pays: result.$data[i].data.pays,
          ville: result.$data[i].data.ville,
          quartier: result.$data[i].data.quartier,
          lon: result.$data[i].data.lon,
          lat: result.$data[i].data.lat,
        };
        let logement = {
          nom: result.$data[i].data.nom,
          ref: result.$data[i].data.ref,
          description: result.$data[i].data.description,
          prixMin: result.$data[i].data.prixMin,
          prixMax: result.$data[i].data.prixMax,
          idSousType: result.$data[i].data.sousTypeLogement,
          idBatiment: result.$data[i].data.batiment,
          adresse: adresse,
          caracteristiques: [
            {
              libelle: "chambre",
              valeur: result.$data[i].data.nombreChambres,
            },
            {
              libelle: "salon",
              valeur: result.$data[i].data.nombreChambres,
            },
            {
              libelle: "cuisine",
              valeur: result.$data[i].data.nombreChambres,
            },
            {
              libelle: "douche",
              valeur:
                result.$data[i].data.nombreDouches != null
                  ? result.$data[i].data.nombreDouches
                  : 0,
            },
            {
              libelle: "parkings",
              valeur:
                result.$data[i].data.nombreParkings != null
                  ? result.$data[i].data.nombreParkings
                  : 0,
            },
          ],
        };
        console.log("logement final", logement);
        this.housingToUpload.push(logement);
      }
      return new Promise((resolve, reject) => {
        let result;

        for (let j = 0; j < this.housingToUpload.length; j++) {
          try {
            result = axios
              .post("logements", this.housingToUpload[j])
              .then((response) => response.result);

            if (j == this.housingToUpload.length - 1) {
              this.getHousing(false);
            }
          } catch (error) {
            console.log(error);
          }
          if (!result) errorState = true;
        }
        setTimeout(() => {
          if (errorState) {
            reject("erreur produite");
            errorState = false;
          } else {
            resolve("import reussi");
          }
        }, 300);
      });
    },
  },
};
</script>
<style scoped>
.dispose {
  margin-top: -0.5em;
}
.text-form-logement {
  font-size: 1.3em;
  color: #212121ef;
  font-weight: 800;
  text-align: center;
  margin-top: 2px;
}
p.rappel {
  font-size: 1.2rem;
  margin-top: 40px;
  font-weight: 500;
  color: #191c22;
}
.left-header {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
}
.headder-logement-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.filter-block {
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout-wrap {
  display: grid;
  grid-template-columns: repeat(5, calc(20% - 20px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 1500px) {
  .layout-wrap {
    grid-template-columns: repeat(4, calc(25% - 20px));
  }
}
@media only screen and (max-width: 1200px) {
  .layout-wrap {
    grid-template-columns: repeat(3, calc(33.3333% - 20px));
  }
}
@media only screen and (max-width: 700px) {
  .layout-wrap {
    grid-template-columns: repeat(2, calc(50% - 20px));
  }
}
</style>
