<template>
    <div class="search-input">
        <form action="" onSubmit="return false">
            <b-form-input type="text" class="input" :placeholder="placeholder" @input="(data) => { $emit('input', data) }" v-model="val" />
            <button type="submit"><i class="ik ik-search"></i></button>
        </form>
    </div>
</template>


<script>

export default {
    name: "SearchForm",
    props: {
        placeholder: { type: String, default: 'Rechercher'},
        value: { required: true, default: ""},
    },
    data: () => ({
        val: null,
    }),
    mounted() {
        this.val = this.value
    }
}
</script>

<style scoped>
    .search-input{
        width: 350px;
        height: auto;
    }
    form{
        display: flex;
        justify-content: space-between;
        background: #fefefe;
        width: 350px;
        height: 50px;
        border-radius: 8px;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.06),
                    inset 0 -2px 4px rgba(0, 0, 0, 0.06);
    }
    .input{
        padding: 0 5px 0 10px;
        height: 50px; 
        width: 350px; 
        border-radius: 8px;
        border: none;
        outline: none;
        font-size: 1rem;
        background: transparent;
    }
    /* .input::placeholder{
        padding: 0 5px 0 10px;
        height: 50px; 
        width: 350px; 
        border-radius: 8px;
        border: none;
        outline: none;
    } */
    button{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%; 
        width:50px;
        background: transparent;
        border: none;
    }
    button i{
        font-size: 1.2rem;
        color: #5c5c5c;
    }
    
     @media screen and (max-width: 800px) {
         
        .search-input{
            width: 100%;
            height: auto;
        }
    }
</style>
