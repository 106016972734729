<template>
  <div class="list-item list-item-grid">
    <!-- <div class="card d-flex flex-row mb-3">
            <a class="d-flex card-img" @click.prevent="showDetails" href="#" style="overflow: hidden; border-radius: 8px 8px 0 0;">
                <b-carousel v-if="logement.photos.length>0" :interval="4000" controls indicators background="#ababab" img-width="200" img-height="10em">
                     <b-carousel-slide style="height: 15em; overflow: hidden; border-radius: 8px 8px 0 0" class="responsive border-0" v-for="(photo, i) in logement.photos" :key="photo.idPhoto || i" :img-src="photo.image|| '/img/bgCity.jpg'" />
                </b-carousel>
                <img v-else :src="`${$router.options.base}img/imagelogementdefault-.jpg`" alt="" style="height: 15em" class="list-thumbnail responsive border-0">
                <span v-if="!logement.etatLogement" class="badge badge-pill badge-danger position-absolute badge-top-left">{{$t('data.logement_etat_libre')}}</span>
                <span v-else class="badge badge-pill badge-success position-absolute badge-top-left">{{$t('data.logement_etat_occupe')}}</span>
            </a>
            <div class="d-flex flex-grow-1 min-width-zero card-content card">
                <div class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                    <div class="text-center">
                        <h4 class="mt-0 truncate" style="color: #f5365c; font-size: 22px;">{{ logement.refLogement }}</h4>
                    </div>
                     <hr>
                    <div class="text-muted w-100 my-1">
                        <span class="d-inline-block w-10 text-center"><i class="fa fa-star"></i></span>
                        <span>{{ logement.sousTypeLogement.libelleSousType }}</span>
                    </div>
                    <div class="text-muted w-100 my-1">
                        <span class="d-inline-block w-10 text-center"><i class="fa fa-map-marker-alt"></i></span>
                        <span>{{ adresse }}</span>
                    </div>
                    <div class="text-muted w-100 my-1">
                        <span class="d-inline-block w-10 text-center"><i class="fa fa-dollar-sign"></i></span>
                        <span class="font-weight-bold"  style="color: #f5365c; font-size: 15px;"><span>{{ logement.prixMin | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span><span>{{ ' - ' }}</span><span>{{ logement.prixMax | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ','  }) }}</span></span>
                    </div>
                    <p v-if="isSub" class="text-muted mt-3 mb-1 date w-15 w-xs-100 text-small">{{ $date(logement.createdAt).format("DD.MM.YYYY") }}</p>
                    <p v-else v-b-tooltip.bottom="dateCreation" class="mt-3 mr-1 mb-1 text-muted text-small date w-15 w-xs-100">{{ $date(logement.createdAt).format("DD.MM.YYYY") }}</p>
                </div>
                <div class="list-actions">
                    <a v-if="!isSub&&canViewDetails" href="#" @click.prevent="showDetails" v-b-tooltip.bottom="$t('data.logement_tooltip_details')"><i class="ik ik-eye"></i></a>
                    <a v-if="!isSub&&canViewAnalysesLogement" href="#" @click.prevent="showAnalyses" v-b-tooltip.bottom="$t('data.logement_tooltip_analyses')"><i class="ik ik-activity"></i></a>
                    <a v-if="canCloneLogement&&!isSub" href="#" @click.prevent="$emit('makeClone', logement)"  v-b-tooltip.bottom="$t('data.logement_tooltip_cloner')"><i class="ik ik-copy"></i></a>
                    <a href="#" @click.prevent="accessTo" v-if="isSub" v-b-tooltip.bottom="$t('data.logement_tooltip_acceder_aux_details')"><i class="ik ik-link"></i></a>
                    <a v-if="canUpdateLogement&&!isSub" href="#" @click.prevent="$emit('makeUpdate', logement)" v-b-tooltip.bottom="$t('data.logement_tooltip_editer')" ><i class="ik ik-edit-2"></i></a>
                    <a v-if="canDeleteLogement" href="#" @click.prevent="remove" class="list-delete" v-b-tooltip.bottom="$t('data.logement_tooltip_supprimer')"><i class="ik ik-trash-2"></i></a>
                </div>
            </div>
        </div> -->
    <div class="annonce-item">
      <b-carousel
        class="caroussel-container"
        v-if="logement.photos.length > 0"
        :interval="0"
        controls
        indicators
      >
        <b-carousel-slide
          class="slide"
          v-for="(photo, i) in logement.photos"
          :key="photo.idPhoto || i"
          :img-src="photo.image || '/img/bgCity.jpg'"
        />
      </b-carousel>
      <div class="annonce-info">
        <span class="title">
          {{ logement.refLogement }}
        </span>
        <span class="logement-type">
          {{ logement.sousTypeLogement.libelleSousType }}
        </span>
        <span class="logement-caract">
          {{ adresse }}
        </span>
        <span class="logement-caract">
          {{
            logement.prixMin
              | currency(devise, 0, {
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ",",
              })
          }}
          -
          {{
            logement.prixMax
              | currency(devise, 0, {
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ",",
              })
          }}
        </span>
        <div class="caract">
          <span v-for="(caract, cle) in logement.caracteristiques" :key="cle">
            {{ caract.valeur }}.
            {{ caract.libelleCaracteristique }}
          </span>
        </div>
        <div class="action-btns">
          <button
            v-b-tooltip.bottom="$t('data.logement_tooltip_details')"
            v-if="!isSub && canViewDetails"
            @click.prevent="showDetails"
          >
            <i class="ik ik-eye"></i>
          </button>
          <div class="barre" v-if="!isSub"></div>
          <button
            v-if="canUpdateLogement && !isSub"
            v-b-tooltip.bottom="$t('data.logement_tooltip_editer')"
            @click.prevent="$emit('makeUpdate', logement)"
          >
            <i class="ik ik-edit-2"></i>
          </button>
          <div class="barre" v-if="!isSub"></div>
          <button
            v-b-tooltip.bottom="$t('data.logement_tooltip_analyses')"
            v-if="!isSub && canViewAnalysesLogement"
            @click.prevent="showAnalyses"
          >
            <i class="ik ik-activity"></i>
          </button>
          <div class="barre" v-if="!isSub"></div>
          <button
            v-b-tooltip.bottom="$t('data.logement_tooltip_cloner')"
            v-if="canCloneLogement && !isSub"
            @click.prevent="$emit('makeClone', logement)"
          >
            <i class="ik ik-copy"></i>
          </button>
          <div class="barre" v-if="!isSub"></div>
          <button
            v-b-tooltip.bottom="$t('data.logement_tooltip_supprimer')"
            v-if="canDeleteLogement"
            @click.prevent="remove"
          >
            <i class="ik ik-trash-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const php = require("phpjs");
import { mapGetters } from "vuex";
export default {
  props: {
    logement: { type: Object, required: true },
    isSub: { type: Boolean, default: false },
  },
  data: () => ({
    devise: "F",
  }),
  computed: {
    ...mapGetters(["hasAccess"]),
    ...mapGetters(["user"]),
    dateCreation() {
      const day = this.$date(this.logement.createdAt);
      return `Crée le ${day.format("dddd, DD MMMM YYYY")} à ${day.format(
        "HH:mm"
      )}`;
    },
    canUpdateLogement() {
      return this.hasAccess("edit_logement");
    },
    canDeleteLogement() {
      return this.hasAccess("delete_logement");
    },
    canViewDetails() {
      return this.hasAccess("details_logement");
    },
    canCloneLogement() {
      return this.hasAccess("clone_logement");
    },
    canViewAnalysesLogement() {
      return this.hasAccess("view_analyses_logement");
    },

    adresse() {
      if (!php.empty(this.logement.adresse)) {
        return `${this.logement.adresse.ville} ${this.logement.adresse.pays}`;
      }
      if (!php.empty(this.logement.batiment)) {
        if (!php.empty(this.logement.batiment.adresse)) {
          return `${this.logement.batiment.adresse.ville} ${this.logement.batiment.adresse.pays}`;
        }
      }
      return "";
    },
  },
  methods: {
    /**
     * Affiche la modale avec les details de l'element
     */
    showDetails() {
      if (this.isSub) {
        return false;
      }
      this.$emit("showDetails", this.logement);
    },
    /**
     * Affiche la modale avec les details de l'element
     */
    showAnalyses() {
      if (this.isSub) {
        return false;
      }
      this.$emit("showAnalyses", this.logement);
    },
    /**
     * Acces rapide aux details de l'element
     */
    accessTo() {
      $("#editLayoutItem").modal("hide");
      this.$router.push({
        name: "logements",
        query: { target: this.logement.idLogement },
      });
    },
    /**
     * Suppression d'un logement
     */
    remove() {
      App.confirm(
        `Voullez vous vraiment supprimer le logement " <b>${this.logement.refLogement}</b> " ?`,
        {
          confirm: () => {
            axios
              .delete(`logements/${this.logement.idLogement}`)
              .then((response) => {
                if (!response.success) {
                  return App.notifyError(response.message);
                }
                this.$emit("deleted", this.logement.idLogement);
                return App.notifySuccess(response.message);
              });
          },
        }
      );
    },
  },
  mounted() {},
};
</script>

<style>
.annonce-item {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 27rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
}
.caroussel-container {
  height: 50%;
  width: 100%;
  border-radius: 8px;
}
.slide {
  width: auto;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  object-fit: contain;
  /* background: teal; */
}
.slide img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  object-fit: cover;
}
.annonce-item .annonce-info {
  padding: 10px 10px 5px;
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
}
.annonce-info .title {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: #191c22;
}
.annonce-info .logement-type {
  margin-bottom: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  color: #5c5c5c;
}
.annonce-info .logement-caract {
  font-size: 0.9rem;
  color: #5c5c5c;
}
.caract {
  margin-top: 10px;
  font-size: 0.95rem;
  color: #5c5c5c;
}
.action-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
  opacity: 0;
  transition: 0.1s ease-in;
}
.annonce-item:hover .action-btns {
  opacity: 1;
}
.action-btns .barre {
  margin: 0 5px;
  height: 80%;
  width: 2px;
  background: #efefef;
}
.action-btns button {
  background: transparent;
  border: none;
}
.action-btns button i {
  font-size: 0.95rem;
  color: #5c5c5c;
}
.card {
  border-radius: 8px;
}
</style>
